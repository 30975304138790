<template>
	<div class="bulk_box">
		<div class="null-page" v-show="yes"></div>
		
		<el-card class="box-card order-list">
			<div slot="header" class="clearfix"><span>发票列表</span></div>

			<el-tabs v-model="invoice_status" @tab-click="handleClick">
				<el-tab-pane label="可开票订单" name="NULL"></el-tab-pane>
				<el-tab-pane label="等待审核" name="0"></el-tab-pane>
				<el-tab-pane label="开票完成" name="1"></el-tab-pane>
			</el-tabs>
			<div class="search_area">
				<div>
					<template v-if="invoice_status == 'NULL'">
						合同编号：
						<input type="text" placeholder="请输入合同编号" v-model="plan_out_trade_no">
					</template>
					<template v-else>
						发票编号：
						<input type="text" placeholder="请输入发票编号" v-model="invoice_no">
					</template>
					申请时间：
					<el-date-picker @change="changeTimeLimit" v-model="date_limit" type="daterange" value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
					</el-date-picker>
					<div class="btn" @click="refresh">搜索</div>
				</div>
			</div>
			<div v-if="invoice_status=='NULL'" class="apply_invoice"><div class="btn" @click="openInvoicePopup(orderItem)">申请发票</div></div>
			<div v-loading="loading" v-if="invoice_status=='NULL'">
				<nav>
					<li>合同编号</li>
					<li>期数</li>
					<li>实付款</li>
					<li>发票状态</li>
					<li>操作</li>
				</nav>
				<div class="list" v-if="orderList.length > 0">
					<div class="item" v-for="(orderItem, orderIndex) in orderList" :key="orderIndex">
						<div class="head">
							<el-checkbox v-model="orderItem.checked"></el-checkbox>
							<span class="create-time">{{ $util.timeStampTurnTime(orderItem.create_time) }}</span>
							<span class="order-no">订单号：{{ orderItem.order_no }}</span>
							<router-link :to="'/shop-' + orderItem.site_id" target="_blank">{{ orderItem.site_name }}</router-link>
							<!-- <span class="order-type">{{ orderItem.order_type_name }}</span> -->
						</div>
						<ul>
							<li>{{orderItem.plan_out_trade_no}}</li>
							<li>第{{orderItem.periods}}期</li>
							<li>￥{{orderItem.plan_amount}}</li>
							<template >
								<li>
									<span class="ns-text-color">{{ orderItem.invoice_status_name ? orderItem.invoice_status_name : '未申请发票' }}</span>
									<!-- <el-link :underline="false" v-if="orderItem.invoice_status_name" @click="orderDetail(orderItem)">发票详情</el-link> -->
								</li>
								<!-- <li>
									<template v-if="!orderItem.invoice_status_name">
										<el-button type="primary" size="mini" :plain="true" @click="openInvoicePopup(orderItem)" >
											申请发票
										</el-button>
									</template>
								</li> -->
							</template>
						</ul>
					</div>
				</div>
				<div v-else-if="!loading && orderList.length == 0" class="empty-wrap">暂无相关订单</div>
			</div>
			<div v-loading="loading" v-else>
				<nav class="list1">
					<li>发票编号</li>
					<li>发票金额(元)</li>
					<li>发票类型</li>
					<li>发票代码</li>
					<li>开票时间</li>
					<li>开票状态</li>
					<li>操作</li>
				</nav>
				<div class="list list1" v-if="orderList.length > 0">
					<div class="item" v-for="(orderItem, orderIndex) in orderList" :key="orderIndex">
						<div class="head">
							<span class="create-time">{{ $util.timeStampTurnTime(orderItem.apply_time) }}</span>
							<!-- <span class="order-type">{{ orderItem.order_type_name }}</span> -->
						</div>
						<ul>
							<li>{{orderItem.invoice_no}}</li>
							<li>￥{{orderItem.invoice_money}}</li>
							<li>{{ orderItem.invoice_type == 1 ? '纸质' : '电子' }}</li>
							<li>{{orderItem.invoice_code || '暂无'}}</li>
							<li>{{ orderItem.drawer_time ? $util.timeStampTurnTime(orderItem.drawer_time) : '暂未开票' }}</li>
							<template >
								<li>
									<span class="ns-text-color">{{ orderItem.invoice_status_name ? orderItem.invoice_status_name : '未申请发票' }}</span>
									<!-- <el-link :underline="false" v-if="orderItem.invoice_status_name" @click="orderDetail(orderItem)">发票详情</el-link> -->
								</li>
								<li>
									<el-button type="primary" size="mini" :plain="true" @click="orderDetail(orderItem)" >
										发票详情
									</el-button>
									<template v-if="!orderItem.invoice_status_name">
										<el-button type="primary" size="mini" :plain="true" @click="openInvoicePopup(orderItem)" >
											申请发票
										</el-button>
									</template>
								</li>
							</template>
						</ul>
					</div>
				</div>
				<div v-else-if="!loading && orderList.length == 0" class="empty-wrap">暂无相关订单</div>
			</div>
			<div class="pager">
				<el-pagination 
					background 
					:pager-count="5" 
					:total="total" 
					prev-text="上一页" 
					next-text="下一页" 
					:current-page.sync="currentPage" 
					:page-size.sync="pageSize" 
					@size-change="handlePageSizeChange" 
					@current-change="handleCurrentPageChange" 
					hide-on-single-page
				></el-pagination>
			</div>
		</el-card>
		<el-dialog title="添加发票信息" :visible.sync="invoiceVisible" width="50%">
			<el-form :model="invoiceForm" :rules="invoiceRules" ref="invoiceForm" label-width="150px">
				<el-form-item label="发票模式" prop="invoice_model">
					<el-radio-group v-model="invoiceForm.invoice_model">
						<el-radio :label="item.value" v-for="(item,index) in modelData"
							:key="index">{{item.name}}</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="发票类型" prop="invoice_type">
					<el-radio-group v-model="invoiceForm.invoice_type">
						<el-radio :label="item.value" v-for="(item,index) in paperData"
							:key="index">{{item.name}}</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="发票抬头类型" prop="invoice_title_type" v-if="invoiceForm.invoice_model == 1">
					<el-radio-group v-model="invoiceForm.invoice_title_type">
						<!-- @change="changeInvoiceTitleType(index + 1)" -->
						<el-radio :label="item.value" v-for="(item,index) in enterpriseData" :key="index" >{{item.name}}</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="请填写抬头名称" prop="invoice_title" v-if="invoiceForm.invoice_title_type == 2">
					<el-input v-model="invoiceForm.invoice_title"></el-input>
				</el-form-item>
				<el-form-item label="请填写单位名称" prop="invoice_title" v-if="invoiceForm.invoice_title_type == 1">
					<el-input v-model="invoiceForm.invoice_title"></el-input>
				</el-form-item>
				<el-form-item label="请输入注册地址" prop="registered_address" v-if="invoiceForm.invoice_model == 2">
					<el-input v-model.trim="invoiceForm.registered_address"></el-input>
				</el-form-item>
				<el-form-item label="请输入注册电话" prop="registered_telephone" v-if="invoiceForm.invoice_model == 2">
					<el-input v-model.trim="invoiceForm.registered_telephone"></el-input>
				</el-form-item>
				<el-form-item label="请输入开户行名称" prop="bank_name" v-if="invoiceForm.invoice_model == 2">
					<el-input v-model.trim="invoiceForm.bank_name"></el-input>
				</el-form-item>
				<el-form-item label="请输入银行账户" prop="bank_account" v-if="invoiceForm.invoice_model == 2">
					<el-input v-model.trim="invoiceForm.bank_account"></el-input>
				</el-form-item>
				<el-form-item label="请填写纳税人识别号" prop="taxpayer_number" v-if="invoiceForm.invoice_title_type == 2">
					<el-input v-model="invoiceForm.taxpayer_number"></el-input>
				</el-form-item>
				<el-form-item label="请填写收票人姓名" prop="accept_name" v-if="invoiceForm.invoice_type == 1">
					<el-input v-model.trim="invoiceForm.accept_name"></el-input>
				</el-form-item>
				<el-form-item label="请填写收票人手机号" prop="accept_telephone" v-if="invoiceForm.invoice_type == 1">
					<el-input v-model.trim="invoiceForm.accept_telephone"></el-input>
				</el-form-item>
				<el-form-item label="请填写发票邮寄地址" prop="invoice_full_address" v-if="invoiceForm.invoice_type == 1">
					<el-input v-model="invoiceForm.invoice_full_address"></el-input>
				</el-form-item>
				<el-form-item label="请填写接收邮箱" prop="invoice_email" v-if="invoiceForm.invoice_type == 2">
					<el-input v-model="invoiceForm.invoice_email"></el-input>
				</el-form-item>
				<el-form-item label="请填写发票备注" prop="invoice_beizhu">
					<el-input v-model="invoiceForm.invoice_remarks"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="invoiceVisible = false">取 消</el-button>
				<el-button type="primary" @click="applyInvoice()">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { OrderInvoicePageList, addInvoice } from '@/api/bulkOrder';
import orderMethod from '@/utils/orderMethod';
export default {
	name: 'order_list',
	components: {},
	data: () => {
		var checkMobile = (rule, value, callback) => {
			if (value === "") {
				callback(new Error("请输入手机号"))
			} else if (!/^1[3|4|5|6|7|8|9][0-9]{9}$/.test(value)) {
				callback(new Error("手机号格式错误"))
				return false;
			} else {
				callback()
			}
		}
		return {
			loading: true,
			orderList: [],
			currentPage: 1,
			pageSize: 10,
			total: 0,
			yes: true,
			start_time:'',
			end_time:'',
			invoice_no:'',
			plan_out_trade_no:'',
			name:'',
			mobile:'',
			date_limit:'',
			invoice_status: 'NULL',
			invoiceVisible:false,  //控制发票弹框的隐藏
			invoiceForm: {
				invoice_type: 1, //发票类型电子、纸质
				invoice_email: '', // 邮箱
				is_tax_invoice: 0, //是否需要增值税发票
				invoice_content: '', //发票内容
				taxpayer_number: '' ,//纳税人识别号
				invoice_title: '', //发票抬头
				invoice_model: 1, //发票模式、 普通，增值
				registered_address:'',//注册地址
				registered_telephone:'',//注册电话
				bank_account:'',//开户行名称
				bank_name:'',//账号
				accept_name:'',//接收人姓名
				accept_telephone:'',//接收电话
				invoice_title_type: 1, // 抬头类型
				invoice_full_address: '', //邮寄地址
				invoice_content_array: [],
				invoice_remarks:''//发票备注
			},
			invoiceRules:{
				is_tax_invoice:[
					{required: true, message: '请选择是否需要增值税发票', trigger: 'change'}
				],
				registered_address:[
					{required: true, message: '请输入注册地址', trigger: 'blur'}
				],
				registered_telephone:[
					{required: true,validator: checkMobile, message: '请输入注册电话', trigger: 'blur'}
				],
				bank_account:[
					{required: true, message: '请输入开户行名称', trigger: 'blur'}
				],
				bank_name:[
					{required: true, message: '请输入银行账号', trigger: 'blur'}
				],
				accept_name:[
					{required: true, message: '请输入接收人姓名', trigger: 'blur'}
				],
				accept_telephone:[
					{required: true,validator: checkMobile, trigger: 'blur'}
				],
				invoice_type:[
					{required: true, message: '请选择发票类型', trigger: 'change'}
				],
				invoice_model:[
					{required: true, message: '请选择发票模式', trigger: 'change'}
				],
				invoice_title_type:[{
					required: true, message: '请选择发票抬头类型', trigger: 'change'
				}],
				invoice_content:[{
					required: false, message: '请选择发票内容', trigger: 'change'
				}],
				invoice_title:[{
					required: true, message: '请填写抬头名称', trigger: 'blur' 
				}],
				taxpayer_number:[{
					required: true, message: '请填写纳税人识别号', trigger: 'blur' 
				}],
				invoice_full_address:[{
					required: true, message: '请填写发票邮寄地址', trigger: 'blur' 
				}],
				invoice_email:[{
					required: true, message: '请填写发票接收邮箱', trigger: 'blur' 
				}],
			},
			paperData: [
				{name: '纸质发票',value: 1},
				{name: '电子发票',value: 2},
			],
			enterpriseData: [
				{name: '个人',value: 1},
				{name: '企业',value: 2},
			],
			modelData:[
				{name: '普通发票',value: 1},
				{name: '增值发票',value: 2},
			],
			add_invoice_plan:'',
		};
	},
	mixins: [orderMethod],
	created() {
		this.invoice_status = this.$route.query.status || 'NULL';
		this.getOrderList();
	},
	computed: {
		...mapGetters(['defaultGoodsImage'])
	},
	mounted() {
		let self = this;
		setTimeout(function() {
			self.yes = false
		}, 300)
	},
	methods: {
		applyInvoice(){
			this.$refs['invoiceForm'].validate((valid) => {
				if (valid) {
					addInvoice({
						invoice_type:this.invoiceForm.invoice_type,
						invoice_email:this.invoiceForm.invoice_email,
						is_tax_invoice:this.invoiceForm.invoice_model == 2 ? 1 : 0,
						invoice_content:this.invoiceForm.invoice_content,
						taxpayer_number:this.invoiceForm.taxpayer_number,
						invoice_title:this.invoiceForm.invoice_title,
						invoice_model:this.invoiceForm.invoice_model,
						registered_address:this.invoiceForm.registered_address,
						registered_telephone:this.invoiceForm.registered_telephone,
						bank_account :this.invoiceForm.bank_account,
						bank_name:this.invoiceForm.bank_name,
						accept_name:this.invoiceForm.accept_name,
						accept_telephone:this.invoiceForm.accept_telephone,
						plan_ids:this.add_invoice_plan,
						invoice_title_type:this.invoiceForm.invoice_title_type,
						invoice_full_address:this.invoiceForm.invoice_full_address,
						invoice_remarks:this.invoiceForm.invoice_remarks,
					})
					.then(res=>{
						if(res.code>=0){
							this.$message({message:'发票申请成功'});
							this.invoiceVisible = false;
							this.getOrderList();
						}else{
							this.$message.error(res.message)
						}
					})
					.catch(err=>{
						this.$message.error(err.message)
					})
				} else {
					return false;
				}
			});
		},
		changeInvoiceTitleType(invoice_title_type) {
			this.invoiceForm.invoice_title_type = invoice_title_type;
			this.$forceUpdate();
		},
		openInvoicePopup(orderData){
			var checked_ids = [];
			this.orderList.forEach(item=>{
				if(item.checked){
					checked_ids.push(item.plan_id)
				}
			})
			if(checked_ids.length){
				this.add_invoice_plan = checked_ids.toString();
				// if(checked_ids.indexOf(orderData.plan_id) != -1){
				// 	this.add_invoice_plan = checked_ids.toString();
				// }else{
				// 	this.add_invoice_plan = orderData.plan_id;
				// }
			}else{
				this.$message.error('请选择申请发票的订单')
				return;
				// this.add_invoice_plan = orderData.plan_id;
			}
			this.invoiceForm={
				invoice_type: 1, //发票类型电子、纸质
				invoice_email: '', // 邮箱
				is_tax_invoice: 0, //是否需要增值税发票
				invoice_content: '', //发票内容
				taxpayer_number: '' ,//纳税人识别号
				invoice_title: '', //发票抬头
				invoice_model: 1, //发票模式、 普通，增值
				registered_address:'',//注册地址
				registered_telephone:'',//注册电话
				bank_account:'',//开户行名称
				bank_name:'',//账号
				accept_name:'',//接收人姓名
				accept_telephone:'',//接收电话
				invoice_title_type: 1, // 抬头类型
				invoice_full_address: '', //邮寄地址
				invoice_content_array: [],
				invoice_remarks:''//发票备注
			},
			this.invoiceVisible = true;
		},
		calcPercent(part,total){
			if(total){
				return Number(((part/total)*100).toFixed(2));
			}else{
				return Number(0);
			}
		},
		changeTimeLimit(e){
			if(e){
				this.start_time = e[0];
				this.end_time = e[1];
			}
		},
		handleClick(tab, event) {
			this.currentPage = 1;
			this.invoice_status = tab.name;
			this.plan_out_trade_no = '';
			this.invoice_no = '';
			this.start_time = '';
			this.end_time = '';
			this.date_limit = '';
			this.refresh();
		},
		getOrderList() {
			OrderInvoicePageList({
				page: this.currentPage,
				page_size: this.pageSize,
				start_time:this.start_time,
				end_time:this.end_time,
				plan_out_trade_no:this.plan_out_trade_no,
				invoice_no:this.invoice_no,
				invoice_status: this.invoice_status,
			})
			.then(res => {
				let list = [];
				if (res.code == 0 && res.data) {
					list = res.data.list;
					this.total = res.data.count;
				}
				this.orderList = list;
				if(this.invoice_status == 'NULL'){
					this.orderList.forEach(item=>{
						this.$set(item,'checked',false);
					})
				}
				this.loading = false;
			})
			.catch(res => {
				this.loading = false;
			});
		},
		handlePageSizeChange(size) {
			this.pageSize = size;
			this.refresh();
		},
		handleCurrentPageChange(page) {
			this.currentPage = page;
			this.refresh();
		},
		refresh() {
			this.loading = true;
			this.getOrderList();
		},
		operation(action, orderData) {
			let index = this.status;
			switch (action) {
				case 'orderPay': // 支付
					this.orderPay(orderData);
					break;
				case 'orderClose': //关闭
					this.orderClose(orderData.order_id, () => {
						this.refresh();
					});
					break;
				case 'memberTakeDelivery': //收货
				console.log('9999')
					this.orderDelivery(orderData.order_id, () => {
						this.refresh();
					});
					break;
				case 'trace': //查看物流
					this.$router.push({ path: '/member/logistics', query: { order_id: orderData.order_id } });
					break;
				case 'memberOrderEvaluation': //评价
					this.$router.pushToTab({ path: '/evaluate', query: { order_id: orderData.order_id } });
					break;
				case 'extendTakeDelivery': //延长收货
					this.orderExtendDelivery(orderData, () => {
						this.refresh();
					});
					break;
			}
		},
		orderDetail(data) {
			this.$router.push('/member/invoice_detail?invoice_id='+data.invoice_id)
		},
		imageError(orderIndex, goodsIndex) {
			this.orderList[orderIndex].order_goods[goodsIndex].sku_image = this.defaultGoodsImage;
		}
	}
};
</script>
<style lang="scss">
	.search_area{
		display: flex;
		align-items: flex-start;
		flex-direction: column;
		>div{
			display: flex;
			align-items: center;
			margin-bottom: 12px;
		}
		.el-date-editor{
			border: 1px solid #E0E0E0;
			border-radius: 0;
			height: auto;
			padding: 8px 10px;
			.el-range-separator{
				line-height: 20px;
			}
			.el-input__icon{
				line-height: 1;
			}
			.el-range-input{
				border: none;
				color: #333;
				font-size: 14px;
				line-height: 20px;
				padding: 0;
			}
		}
		input{
			border: 1px solid #E0E0E0;
			outline: none;
			color: #333;
			font-size: 14px;
			line-height: 20px;
			padding: 8px 12px;
			margin-right: 12px;
		}
		input::placeholder{
			color: #888;
			font-size: 14px;
			line-height: 20px;
		}
		.btn{
			color: #FFF;
			font-size: 12px;
			line-height: 16px;
			padding: 6px 18px;
			border-radius: 2px;
			background-color: #00A98F;
			margin-left: 10px;
			cursor: pointer;
		}
	}
	.list{
		.head{
			.el-checkbox{
				margin-right: 10px;
				.el-checkbox__inner{
					width:20px;
					height: 20px;
					&::after{
						width: 4px;
						height: 8px;
						top: 42%;
						left: 50%;
						transform: translate(-50%,-50%) rotate(45deg) scale(1.2);
					}
				}
			}
		}
	}
</style>
<style lang="scss" scoped>
.apply_invoice{
	display: flex;
	align-items: center;
	padding-left: 8px;
	.btn{
		font-size: 14px;
		line-height: 18px;
		border-radius: 4px;
		padding: 5px 10px;
		color: #fff;
		background-color: #00a98f;
		cursor: pointer;
	}
	
}
.bulk_box {
	width: 100%;
	position: relative;
}

.null-page {
	width: 100%;
	height: 730px;
	background-color: #FFFFFF;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 9;
}

.order-list {
	nav {
		overflow: hidden;
		padding: 10px;
		background: #fff;
		margin-bottom: 10px;
		border-bottom: 1px solid #eeeeee;
		li {
			float: left;
			&:nth-child(1) {
				width: 40%;
			}
			&:nth-child(2) {
				width: 10%;
			}
			&:nth-child(3) {
				width: 13%;
			}
			&:nth-child(4) {
				width: 17%;
			}
			&:nth-child(5) {
				width: 10%;
			}
			&:nth-child(6) {
				width: 10%;
			}
		}
		&.list1{
			li{
				&:nth-child(1) {
					width: 19%;
				}
				&:nth-child(2) {
					width: 13%;
				}
				&:nth-child(3) {
					width: 10%;
				}
				&:nth-child(4) {
					width: 19%;
				}
				&:nth-child(5) {
					width: 18%;
				}
				&:nth-child(6) {
					width: 10%;
				}
				
			}
		}
	}
	.list {
		.item {
			margin-bottom: 20px;
			border: 1px solid #eeeeee;
			border-top: 0;
			.head {
				padding: 8px 10px;
				background: #f7f7f7;
				font-size: 12px;
				
				.create-time {
					margin-right: 10px;
				}

				border-bottom: 1px solid #eeeeee;
				a {
					margin: 0 10px 0 20px;
				}

				.order-type {
					margin-left: 30px;
				}
			}
		}
		ul {
			background-color: #fff;
			padding: 10px;
			overflow: hidden;
			li {
				float: left;
				min-height: 60px;
				// line-height: 60px;
				&:nth-child(1) {
					width: 40%;
					line-height: inherit;
					.img-wrap {
						width: 60px;
						height: 60px;
						float: left;
						margin-right: 10px;
						cursor: pointer;
					}
					.info-wrap {
						margin-left: 70px;
						h5 {
							font-weight: normal;
							font-size: $ns-font-size-base;
							display: -webkit-box;
							-webkit-box-orient: vertical;
							-webkit-line-clamp: 2;
							overflow: hidden;
							margin-right: 10px;
							display: inline-block;
							cursor: pointer;
							&:hover {
								color: $base-color;
							}
						}
						span {
							font-size: $ns-font-size-sm;
							color: #9a9a9a;
						}
					}
				}
				&:nth-child(2) {
					display: flex;
					align-items: flex-start;
					flex-direction: column;
					width: 10%;
					
				}
				&:nth-child(3) {
					width: 13%;
					padding-right: 10px;
					box-sizing: border-box;
				}
				&:nth-child(4) {
					display: flex;
					align-items: flex-start;
					flex-direction: column;
					width: 17%;
					span{
						&:nth-child(2),
						&:nth-child(3){
							display: flex;
							align-items: center;
							font-size: 13px;
							line-height: 18px;
						}
					}
				}
				&:nth-child(5) {
					width: 10%;
					a {
						display: block;
					}
				}
				&:nth-child(6) {
					width: 10%;
					button {
						margin-left: 0;
						margin-bottom: 10px;
						&:last-child {
							margin-bottom: 0;
						}
					}
				}
			}
		}
		&.list1{
			ul{
				li{
					&:nth-child(1) {
						width: 19%;
					}
					&:nth-child(2) {
						width: 13%;
					}
					&:nth-child(3) {
						width: 10%;
					}
					&:nth-child(4) {
						width: 19%;
					}
					&:nth-child(5) {
						width: 18%;
					}
					&:nth-child(6) {
						width: 10%;
						button {
							margin-left: 0;
							margin-bottom: 10px;
							&:last-child {
								margin-bottom: 0;
							}
						}
					}
				}
			}
		}
	}
	.empty-wrap {
		text-align: center;
		padding: 10px;
	}
}
</style>
